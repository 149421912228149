import React from 'react';

import centroid from '@turf/centroid';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import DesktopIcon from '@mui/icons-material/DesktopWindowsOutlined';
import PhoneIcon from '@mui/icons-material/PhoneAndroid';
import StraightenIcon from '@mui/icons-material/Straighten';

import staticWorkingZones from '@assets/data/working_zones.json';
import { EDITOR_URL, FIELDWORK_URL, HIDE_CONFIG } from '@src/config';
import useCallApi from '@src/hooks/useCallApi';
import Page from '@src/components/Page';


const MAY_USE_STATIC_WORKING_ZONES = HIDE_CONFIG;

function humanizeLength(length, keep_meters) {
  const unit = keep_meters ? 'm' : (length > 500 ? 'km' : 'm');
  const decimals = unit == 'm' ? 0 : 3;
  const value = unit == 'm' ? length : length / 1000;
  const formatted = value.toLocaleString(
    'fr-FR',
    { minimumFractionDigits: 0, maximumFractionDigits: decimals },
  );
  return `${formatted} ${unit}`;
}


function ZoneStats({ zone }) {
  const zoneStats = useCallApi(`/working-zones/${zone.properties.id}/stats`);
  const waysLength = zoneStats?.ways_length ?? 0;
  const formattedLength = humanizeLength(waysLength);
  return (
    <>
      {waysLength ? (
        <ListItemText>
          <Chip
            icon={<StraightenIcon />}
            label={humanizeLength(waysLength)}
            title={`${humanizeLength(waysLength, true)} dessinés`}
          />
        </ListItemText>
      ) : null}
    </>
  );
}


function EditorLink({ zone }) {
  const mapCenter = centroid(zone);
  const [lon, lat] = mapCenter.geometry.coordinates;
  const id = zone.properties?.id ?? 0;
  const name = zone.properties?.title || zone.properties?.name || `Zone ${id}`;
  return (
    <ListItem>
      <IconButton
        component="a" href={`${EDITOR_URL}#map=18/${lat}/${lon}`}
        title="Ouvrir dans l'éditeur de bureau"
        aria-label="Ouvrir dans l'éditeur de bureau"
      >
        <DesktopIcon/>
      </IconButton>
      <IconButton
        component="a" href={`${FIELDWORK_URL}?map=18/${lon}/${lat}`}
        title="Ouvrir dans l'éditeur de terrain"
        aria-label="Ouvrir dans l'éditeur de terrain"
      >
        <PhoneIcon/>
      </IconButton>
      <Stack
        direction="row"
        spacing={2}
        sx={{ flexWrap: 'wrap' }}
      >
        <ListItemText>
          {name}
        </ListItemText>
        <ZoneStats zone={zone}/>
      </Stack>
    </ListItem>
  );
}

export default function WorkingZones() {
  const workingZones = useCallApi('/working-zones/');
  let features = workingZones?.features ?? [];
  if (!features.length && MAY_USE_STATIC_WORKING_ZONES) {
    features = staticWorkingZones.features;
  }
  return (
    <Page
      title={<Typography variant="h1">Zones de travail</Typography>}
    >
      <List>
        {features.map((feature) => (
          <EditorLink key={feature.properties.id} zone={feature}/>
        ))}
      </List>
    </Page>
  );
}
